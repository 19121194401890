import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueGlide from 'vue-glide-js'
import 'vue-glide-js/dist/vue-glide.css'




let VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo, {
  container: "#mainPageContent, #porftolioPageContent",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

Vue.use(VueGlide),


Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
