<template>

  <router-link :to="{
        name: 'about',
      }" class="header"
      v-scroll-to="'#about'">
    <!-- <img  v-bind:src='headerImage.logo' alt="" class="logo"> -->
    <!-- <div v-bind:style="{'mask-image': headerImage.logo}" class="logo"></div> -->
    <svg class="logo" viewBox="0 0 109 38" xmlns="http://www.w3.org/2000/svg">
<path d="M9.20964 6.93224C10.0535 7.56996 10.4546 8.3209 10.4205 9.19636V14.7472C10.3641 15.0957 10.3825 15.4521 10.4744 15.793C10.5664 16.1339 10.7299 16.4514 10.954 16.7246L8.30528 18.1321C8.03535 17.7145 7.90922 16.9447 7.92688 15.8227C7.39203 16.3826 6.82338 16.9093 6.2241 17.4C5.60453 17.8231 4.86712 18.041 4.11644 18.0227C3.17424 18.0566 2.20176 17.6453 1.19523 16.7698C0.714845 16.3892 0.360227 15.8735 0.177376 15.2894C-0.00547475 14.7054 -0.00812086 14.08 0.169781 13.4944C0.332807 12.8512 0.637918 12.2524 1.06281 11.7418C1.4877 11.2313 2.02163 10.8218 2.62557 10.5435C3.93734 9.87182 4.99432 9.59006 5.79652 9.69824C6.54283 9.7914 7.25894 10.0492 7.89283 10.4529C7.97355 8.9058 7.74273 7.87562 7.20036 7.36242C6.658 6.84922 5.90121 6.6857 4.92999 6.87186C3.9613 7.05551 3.55263 7.69701 3.70399 8.79636L0.676831 10.1624C0.512859 8.54228 1.08928 7.39512 2.4061 6.72092C3.55329 6.08816 4.85399 5.7851 6.16356 5.84546C7.26371 5.90254 8.32286 6.28042 9.20964 6.93224ZM7.99878 11.4567L6.77278 12.7699C6.32336 12.331 5.79072 11.9857 5.20599 11.7544C4.62125 11.5231 3.99615 11.4104 3.36722 11.4227C2.13744 11.4643 1.60768 12.0152 1.77039 13.0831C1.9331 14.151 2.62557 15.0453 3.85535 15.7585C5.08513 16.4717 6.45114 16.2453 7.96094 15.0416V12.1359L7.99878 11.4567Z" class="color" />
<path d="M15.0218 5.73981V8.38128C16.1115 7.07312 17.2846 6.31842 18.5408 6.11716C19.0792 5.99653 19.6365 5.98617 20.1789 6.08672C20.7214 6.18726 21.2377 6.3966 21.6966 6.70206C22.1135 6.99893 22.4377 7.40727 22.6319 7.87999C22.826 8.3527 22.8822 8.87053 22.794 9.37372V17.0038L20.1869 18.1359V9.83409C20.2323 8.59637 19.6155 7.87689 18.3365 7.67563C17.7317 7.57021 17.1093 7.63839 16.5418 7.87223C15.9744 8.10607 15.4853 8.49593 15.1315 8.99637V16.9359L12.4562 18.0679V6.74734L15.0218 5.73981Z" class="color" />
<path d="M35.5648 0.287048V17.1887L33.0106 18.1698V16.551C31.3608 18.0604 29.6845 18.4629 27.9818 17.7585C27.1804 17.4423 26.4628 16.9463 25.8847 16.3089C25.3066 15.6715 24.8835 14.9099 24.6481 14.0831C24.1133 12.3498 24.4085 10.6769 25.5336 9.06429C26.6586 7.45174 27.9061 6.45426 29.2759 6.07188C29.8875 5.88103 30.5325 5.82004 31.1692 5.89283C31.8059 5.96562 32.4203 6.17058 32.9728 6.49451V1.37383L35.5648 0.287048ZM29.764 7.6379C28.8596 7.51337 28.0007 7.91336 27.1947 8.84543C26.3887 9.77749 26.226 10.9511 26.7028 12.3775C27.0924 13.697 27.9849 14.8118 29.1888 15.4831C29.7652 15.7972 30.4064 15.9746 31.0627 16.0014C31.719 16.0283 32.3726 15.9038 32.9728 15.6378V8.64543L32.1176 9.23033C31.8606 8.81399 31.5179 8.45678 31.1122 8.18228C30.7064 7.90777 30.2469 7.72223 29.764 7.6379Z" class="color" />
<path d="M40.0451 6.00394V8.36618C41.3669 6.60519 42.6863 5.80898 44.0031 5.97753C45.3199 6.13602 46.0653 6.76242 46.2432 7.8643L43.8479 9.4039C43.7496 8.45674 43.322 7.89449 42.5576 7.68317C41.7933 7.47185 40.9986 7.92467 40.1624 9.00391V16.8906L37.5817 18.0717V7.00016L40.0451 6.00394Z" class="color" />
<path d="M55.983 8.39259C56.3841 9.77371 55.6437 11.0869 53.7619 12.3322L49.9779 14.4152C50.4206 15.2189 51.3401 15.6567 52.7364 15.7208C54.1327 15.785 55.5138 15.1057 56.8798 13.6718L57.0652 13.8189C56.462 14.9552 55.6524 15.9699 54.6776 16.8114C53.6837 17.6843 52.6746 18.0881 51.6504 18.0227C50.7018 17.9871 49.7792 17.7034 48.9752 17.2C48.1524 16.6282 47.522 15.8219 47.1664 14.8868C46.7053 13.6796 46.6469 12.3563 46.9999 11.1133C47.3586 9.62961 48.1784 8.29696 49.3422 7.30582C50.5606 6.21904 51.8737 5.78257 53.2813 5.9964C53.9261 6.06469 54.5332 6.33245 55.0177 6.76215C55.5022 7.19186 55.8398 7.76197 55.983 8.39259ZM48.733 11.5171C48.8696 12.3457 49.1542 13.1432 49.573 13.8718C52.0452 12.6139 53.391 11.5372 53.6105 10.6416C53.7192 10.2138 53.7059 9.76425 53.5722 9.34356C53.4385 8.92287 53.1897 8.54777 52.8537 8.26052C52.1448 7.57122 51.3881 7.39261 50.5833 7.72468C49.8647 7.99006 49.2738 8.51733 48.9298 9.20013C48.6212 9.93254 48.5524 10.7434 48.733 11.5171Z" class="color" />
<path d="M67.2895 6.93223C68.1321 7.57121 68.5357 8.32592 68.5004 9.19635V14.7472C68.4447 15.0957 68.4634 15.452 68.5554 15.7927C68.6473 16.1335 68.8104 16.451 69.0339 16.7246L66.3852 18.1321C66.1153 17.7145 65.9891 16.9447 66.0068 15.8227C65.4719 16.3825 64.9033 16.9093 64.304 17.4C63.6847 17.8236 62.9471 18.0415 62.1963 18.0226C61.2541 18.0566 60.2817 17.6453 59.2751 16.7698C58.7947 16.3892 58.4401 15.8734 58.2573 15.2894C58.0744 14.7053 58.0718 14.08 58.2497 13.4944C58.4117 12.8498 58.7163 12.2495 59.1413 11.7376C59.5662 11.2256 60.1007 10.8151 60.7055 10.536C62.0185 9.86426 63.0742 9.58125 63.8802 9.69068C64.6253 9.78389 65.3402 10.0417 65.9727 10.4454C66.0509 8.89824 65.8201 7.86806 65.2803 7.35486C64.7429 6.84166 63.9861 6.6794 63.0099 6.8643C62.0336 7.04921 61.6325 7.69071 61.7839 8.78881L58.7567 10.1548C58.5978 8.53724 59.1767 7.39134 60.4936 6.71713C61.6407 6.08437 62.9415 5.78132 64.251 5.84167C65.3491 5.90065 66.4056 6.27985 67.2895 6.93223ZM66.0673 11.4605L64.8413 12.7737C64.3919 12.3347 63.8593 11.9895 63.2745 11.7582C62.6898 11.5269 62.0647 11.4141 61.4358 11.4265C60.2098 11.468 59.6762 12.019 59.8389 13.0869C60.0016 14.1548 60.7017 15.0491 61.9277 15.7736C63.1537 16.4981 64.5235 16.2604 66.0333 15.0566V12.151L66.0673 11.4605Z" class="color" />
<path d="M77.8732 6.17751C78.5723 6.39038 79.173 6.84367 79.5684 7.45674L77.8921 9.20766C77.9564 8.9171 77.5137 8.51333 76.5488 8.00013C75.5839 7.48693 74.7628 7.33221 74.0627 7.52844C73.3627 7.72466 73.0486 8.10201 73.1243 8.6605C73.2 9.21898 74.1233 9.79256 75.8904 10.3737C77.6575 10.9548 78.8116 11.5322 79.3489 12.0718C79.8438 12.5236 80.1458 13.1477 80.1927 13.8151C80.2093 14.4795 80.0146 15.1321 79.6365 15.6793C79.179 16.3569 78.5943 16.94 77.9148 17.3962C77.1637 17.9095 76.2617 18.1567 75.3531 18.0981C74.5049 18.0804 73.6662 17.9157 72.8746 17.6113C72.2318 17.3637 71.6451 16.9903 71.1491 16.5132C70.6706 16.0248 70.2859 15.4531 70.0139 14.8265L72.0762 13.2869C72.2179 13.7556 72.445 14.1943 72.7459 14.5812C73.1222 15.0979 73.5888 15.5427 74.1233 15.8944C74.7186 16.2856 75.4004 16.5265 76.1099 16.5962C76.4136 16.6603 76.7279 16.6567 77.0301 16.5856C77.3323 16.5145 77.615 16.3777 77.858 16.1849C78.0545 16.0256 78.1951 15.8082 78.2597 15.5641C78.3243 15.32 78.3095 15.0617 78.2175 14.8265C78.0435 14.2881 77.1277 13.7359 75.4704 13.1699C73.813 12.6038 72.693 12.073 72.1102 11.5774C71.827 11.3402 71.6033 11.0403 71.4568 10.7016C71.3103 10.3629 71.2451 9.99476 71.2664 9.62652C71.2492 8.92637 71.4766 8.24202 71.9097 7.6907C72.2896 7.23221 72.7452 6.84171 73.2568 6.536C73.9096 6.20329 74.6221 6.00319 75.3531 5.94733C76.1996 5.82562 77.0629 5.90448 77.8732 6.17751Z" class="color" />
<path d="M40.1851 19.883L40.1624 28.1093C41.4742 26.0817 42.9108 25.2515 44.4723 25.6188C45.1968 25.7615 45.8682 26.0993 46.4138 26.5956C46.9595 27.0918 47.3585 27.7275 47.5676 28.4338C48.0623 29.7356 48.1154 31.1632 47.7189 32.4979C47.2116 33.855 46.3365 35.045 45.1913 35.9356C43.9047 37.0224 42.7102 37.5909 41.6079 37.6413C40.2579 37.6614 38.9117 37.4937 37.6082 37.1431L37.5817 20.9962L40.1851 19.883ZM40.1624 29.064V34.7243C41.7415 35.3784 43.0029 35.5771 43.9463 35.3205C44.8923 35.0639 45.528 34.3885 45.861 33.2904C46.1833 32.106 46.0214 30.8426 45.4107 29.7772C44.775 28.5194 43.9678 27.8628 42.989 27.8074C42.0102 27.7521 41.068 28.1709 40.1624 29.064Z" class="color" />
<path d="M56.683 37.645V35.2715C55.2729 36.6677 54.1617 37.4413 53.3494 37.5922C52.91 37.658 52.4618 37.6358 52.031 37.527C51.6003 37.4181 51.1956 37.2248 50.8406 36.9582C49.9804 36.3796 49.5503 35.4815 49.5503 34.2639V26.9018L52.078 25.6263V33.8111C52.1839 35.0488 52.7957 35.7017 53.9132 35.7696C54.4146 35.8188 54.9203 35.7408 55.3834 35.5429C55.8465 35.3449 56.2519 35.0335 56.562 34.6375V26.6225L59.1426 25.5697V36.6375L56.683 37.645Z" class="color" />
<path d="M70.0556 28.015C70.4542 29.3961 69.7125 30.7093 67.8306 31.9546L64.0467 34.0375C64.4894 34.8413 65.4089 35.279 66.8052 35.3432C68.2015 35.4073 69.5864 34.7281 70.9675 33.2942L71.153 33.4413C70.5484 34.5768 69.739 35.5912 68.7653 36.4337C67.7562 37.3042 66.7472 37.7079 65.7381 37.645C64.7908 37.6088 63.8696 37.3251 63.0667 36.8224C62.2429 36.2516 61.6123 35.445 61.2579 34.5092C60.793 33.3028 60.7345 31.9782 61.0914 30.7357C61.4486 29.2523 62.2671 27.9196 63.4299 26.9282C64.6509 25.8414 65.9639 25.405 67.369 25.6188C68.0109 25.6906 68.6144 25.9599 69.0958 26.3893C69.5772 26.8186 69.9127 27.3868 70.0556 28.015ZM62.8056 31.1395C62.9462 31.9682 63.2332 32.7654 63.6532 33.4942C66.1279 32.2363 67.4737 31.1596 67.6906 30.264C67.8007 29.8379 67.7899 29.3897 67.6597 28.9693C67.5294 28.5489 67.2847 28.1728 66.9528 27.8829C66.2414 27.1924 65.4922 27.015 64.6824 27.3471C63.9624 27.6116 63.37 28.139 63.025 28.8225C62.7112 29.5533 62.6383 30.3647 62.8169 31.1395H62.8056Z" class="color" />
<path d="M76.8213 21.6075V26.1697H82.5048L78.4711 28.2112H76.8364L76.7418 31.9395C76.5501 33.8061 77.0206 35.0111 78.1532 35.5545C79.2859 36.0979 80.435 35.64 81.6004 34.1809L81.7858 34.4866C81.6271 35.1071 81.3333 35.685 80.9252 36.1794C80.5171 36.6738 80.0047 37.0724 79.4246 37.3469C78.8781 37.6127 78.2701 37.7276 77.6639 37.6799C77.0577 37.6321 76.4754 37.4233 75.9775 37.0752C74.8372 36.3583 74.2633 34.9168 74.2558 32.7508L74.4033 28.2225H71.607L73.8395 26.249H74.4109V22.5773L76.8213 21.6075Z" class="color" />
<path d="M86.3871 19.9774V36.5469L83.8216 37.713V21.0415L86.3871 19.9774Z" class="color" />
<path d="M97.8298 28.015C98.2334 29.4036 97.493 30.7168 95.6086 31.9546L91.8246 34.0375C92.2674 34.8413 93.1869 35.279 94.5869 35.3432C95.987 35.4073 97.3643 34.7281 98.7493 33.2942L98.9347 33.4413C98.3303 34.5779 97.5194 35.5926 96.5432 36.4337C95.5342 37.3042 94.5251 37.7079 93.5161 37.645C92.5674 37.6094 91.6448 37.3257 90.8408 36.8224C90.0171 36.2516 89.3864 35.445 89.0321 34.5092C88.578 33.4527 88.5225 32.1948 88.8656 30.7357C89.2226 29.2513 90.0427 27.9182 91.2078 26.9282C92.4263 25.8414 93.7381 25.405 95.1432 25.6188C95.785 25.6906 96.3886 25.9599 96.87 26.3893C97.3514 26.8186 97.6869 27.3868 97.8298 28.015ZM90.5797 31.1395C90.7168 31.9675 91 32.7647 91.416 33.4942C93.8907 32.2363 95.2365 31.1596 95.4534 30.264C95.5621 29.8362 95.5489 29.3866 95.4152 28.966C95.2815 28.5453 95.0326 28.1702 94.6967 27.8829C93.9853 27.1924 93.2361 27.015 92.4263 27.3471C91.7063 27.6116 91.1139 28.139 90.7689 28.8225C90.4614 29.5554 90.3952 30.3667 90.5797 31.1395Z" class="color" />
<path d="M102.802 25.6263V27.9886C104.124 26.2276 105.443 25.4314 106.76 25.5999C108.077 25.7584 108.822 26.3848 109 27.4867L106.605 29.0263C106.511 28.0779 106.081 27.5043 105.314 27.3055C104.558 27.1093 103.755 27.547 102.923 28.6263V36.513L100.327 37.6865V26.6225L102.802 25.6263Z" class="color" />
</svg>

  </router-link>
</template>

<script>
import store from "@/store.js";
export default {
  name: 'theHeader',
  props: {
    msg: String

  },
    data() {
    return {
      headerImage: store.header,
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header{
  grid-area: header;
  border-bottom:$border;
  // border-right:$border;
  display: grid;
  place-items: center;
  justify-content: center;
  background: linear-gradient(#ffffff,#ffffff00);
}
.logo{
  height: 70%;
  // top:10px;
  // left: 20px;
  align-self: center;
  justify-self: center;
  padding: 0;
  // background-color: $primaryColor;
  // width: 50px;
}
.color{
  fill: $primaryColor;
  mix-blend-mode: normal;
}
</style>
