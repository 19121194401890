var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.$route.meta.backButton && !_vm.$route.meta.portfolio)?_c('nav',{staticClass:"sidenav fourElements"},[_c('router-link',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:('#about'),expression:"'#about'"}],staticClass:"navElement",attrs:{"to":{
        name: 'about',
      }}},[_c('h2',[_vm._v("about")])]),_c('router-link',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:('#projects'),expression:"'#projects'"}],staticClass:"navElement",attrs:{"to":{
        name: 'projects',
      }}},[_c('h2',[_vm._v("projects")])]),_c('router-link',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:('#experiments'),expression:"'#experiments'"}],staticClass:"navElement",attrs:{"to":{
        name: 'experiments',
      }}},[_c('h2',[_vm._v("experiments")])]),_c('router-link',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:('#contact'),expression:"'#contact'"}],staticClass:"navElement",attrs:{"to":{
        name: 'contact',
      }}},[_c('h2',[_vm._v("contact")])])],1):_vm._e(),(!_vm.$route.meta.backButton && _vm.$route.meta.portfolio)?_c('nav',{staticClass:"sidenav threeElements"},[_c('router-link',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:('#portfolioAbout'),expression:"'#portfolioAbout'"}],staticClass:"navElement",attrs:{"to":{
        name: 'portfolioAbout',
      }}},[_c('h2',[_vm._v("about")])]),_c('router-link',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:('#portfolioMain'),expression:"'#portfolioMain'"}],staticClass:"navElement",attrs:{"to":{
        name: 'portfolioMain',
      }}},[_c('h2',[_vm._v("portfolio")])]),_c('router-link',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:('#portfolioContact'),expression:"'#portfolioContact'"}],staticClass:"navElement",attrs:{"to":{
        name: 'portfolioContact',
      }}},[_c('h2',[_vm._v("contact")])])],1):_vm._e(),(_vm.$route.meta.backButton)?_c('nav',{staticClass:"sidenav"},[_c('div',{staticClass:"navElement back",on:{"click":function($event){return _vm.$router.back()}}},[_c('h2',[_vm._v("back")])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }