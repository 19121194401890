<template>
<div>
  <nav class="sidenav fourElements" v-if="!$route.meta.backButton && !$route.meta.portfolio">
    <router-link
      :to="{
        name: 'about',
      }"
      class="navElement"
      v-scroll-to="'#about'"
      ><h2>about</h2></router-link
    >
    <router-link
      :to="{
        name: 'projects',
      }"
      class="navElement"
      v-scroll-to="'#projects'"
      ><h2>projects</h2></router-link
    >
    <router-link
      :to="{
        name: 'experiments',
      }"
      class="navElement"
      v-scroll-to="'#experiments'"
      ><h2>experiments</h2></router-link
    >
    <router-link
      :to="{
        name: 'contact',
      }"
      class="navElement"
      v-scroll-to="'#contact'"
      ><h2>contact</h2></router-link>
  </nav>


    <nav class="sidenav threeElements" v-if="!$route.meta.backButton && $route.meta.portfolio">
    <router-link
      :to="{
        name: 'portfolioAbout',
      }"
      class="navElement"
      v-scroll-to="'#portfolioAbout'"
      ><h2>about</h2></router-link
    >
    <router-link
      :to="{
        name: 'portfolioMain',
      }"
      class="navElement"
      v-scroll-to="'#portfolioMain'"
      ><h2>portfolio</h2></router-link
    >
    <router-link
      :to="{
        name: 'portfolioContact',
      }"
      class="navElement"
      v-scroll-to="'#portfolioContact'"
      ><h2>contact</h2></router-link>
  </nav>


  <nav  class="sidenav" v-if="$route.meta.backButton">
     <div class="navElement back"
      @click="$router.back()"

    ><h2>back</h2>
</div>
  </nav>

  
  </div>
</template>

<script>
import store from "@/store.js";

export default {
  name: "navigaton",
  props: {
    msg: String,
  },
  components: {},
  data() {
    return {
      content: store.content,
    };
  },
  
};



</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.sidenav {
  display: grid;
  grid-area: navbar;
  //justify-content: center;
  //justify-items: stretch;
  align-content: stretch;
  //align-items: center;
  flex-direction: column;
  //grid-auto-rows: inherit;
  height: 100%;
  z-index: 5;
}
.sidenav .navElement .back{
  grid-row-start: 1;
  grid-row-end: 5;
}
.sidenav .navElement {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  //text-transform: uppercase;
  cursor: url("../assets/icons/cursorDot.svg"), pointer;
  border-top: $border;
  border-left: $border;
  text-decoration: none;
  writing-mode: tb;
  transform: rotate(-180deg);
  
  transition: ease-in-out 100ms;
}
.activeMenu {
  background-color: $primaryColor;
  color: $backgroundColor;
  font-variation-settings: "wght" 100;
}
.navElement:hover{
  
  border-left: solid white 2px;
}
.back{
  grid-row-start: 1;
  grid-row-end: 5;
}
.back:hover{
  background-color: $primaryColor;
}
.back:hover h2{
  color: $backgroundColor;

}

.fourElements{
grid-template-rows: 1fr 1fr 1fr 1fr;
}
.threeElements{
grid-template-rows: 1fr 1fr 1fr;
}
 
</style>
