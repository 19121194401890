export default {
    header: {
        logo: require("@/assets/andreasbuetlerlogo.svg"),
    },
    content: {
        cv: {
            general: {
                firstname: "Andreas",
                surname: "Bütler",
                dateOfBirth: "09.04.1998",
                email: "info@andreasbuetler.ch",
                phone: "076 0305 75 95",
            },
            languages: [{
                    language: "german",
                    experience: "mother tongue"
                },
                {
                    language: "french",
                    experience: "8 years of experience"
                },
                {
                    language: "english",
                    experience: "fluent"
                },
            ],
            education: [{
                    timeframe: "2004-2010",
                    title: "primary school st.gallen, switzerland",
                },
                {
                    timeframe: "2010-2012",
                    title: "secondary school st.gallen, switzerland",
                },
                {
                    timeframe: "2012-2016",
                    title: "gymnasium st.gallen, switzerland",
                },
                {
                    timeframe: "2016-2017",
                    title: "preparatory course st.gallen, switzerland",
                },
                {
                    timeframe: "2018-2021",
                    title: "bachelor interaction design zhdk zurich, switzerland",
                },
            ],
            jobs: [{
                    timeframe: "2014-2015",
                    title: "cleaning works in casablanca club st.gallen",
                },
                {
                    timeframe: "since 2014",
                    title: "member of talhof club st.gallen; bartender and technician",
                },
                {
                    timeframe: "2015-2017",
                    title: "administrative work at curtius dance school",
                },
                {
                    timeframe: "2015-2017",
                    title: "cleanig works in galleria bar st.gallen",
                },
                {
                    timeframe: "2018",
                    title: "civil service at thrift shop degersheim",
                },
                {
                    timeframe: "2018",
                    title: "civil service at youth cultural center 'flon' st.gallen",
                },
                {
                    timeframe: "since 2016",
                    title: "promotion swissmilk",
                },
                {
                    timeframe: "since 2016",
                    title: "smaller light-operator projects at FSL Schweiz",
                },
                {
                    timeframe: "2019",
                    title: "bartender at maag gatstro & events",
                },
                {
                    timeframe: "since 2021",
                    title: "videojockey at reckless collective",
                },
                {
                    timeframe: "since 2021",
                    title: "self employed as interaction designer",
                },

            ],
            formations: [{
                    title: "shleter 12",
                    timeframe: "since 2016",
                    description: "music collective",
                    url: "https://shelter12.com/"
                },
                {
                    title: "mo'",
                    timeframe: "since 2019",
                    description: "think tank",
                    url: "https://mowebsite.ch/"
                },
                {
                    title: "talhof",
                    timeframe: "since 2014",
                    description: "event location",
                    url: "https://talhof.sg/"
                },
                {
                    title: "FAR",
                    timeframe: "since 2021",
                    description: "think & do tank",
                    url: ""
                },
                {
                    title: "sowieso",
                    timeframe: "since 2021",
                    description: "design agency",
                    url: "https://so-wie-so.ch/"
                },
                {
                    title: "DEG",
                    timeframe: "since 2020",
                    description: "Bachelor Thesis",
                    url: "https://dialoguing-with-ecologies.org/"
                },
            ],

        },
        about: {
            image: require("@/assets/about//ProfilePicture.jpg"),
            text: "Hello, I'm Andreas Bütler, a multidisciplinary designer who strives to create holistic experiences. I try to combine the fields of light and movement in space through digital as well as physical solutions. <br/><br/> On this website you'll find some selected projects and experiments. If you're interested in getting to know me, feel free to contact me. <br/> Do not hesitate to visit the linked websites of projects i am involved in too:  ",
            formations:[{
                title: "Shelter 12",
                description: "Music Collective",
                url: "https://shelter12.com/"
            },
            {
                title: "mo'",
                description: "Design Think Tank",
                url: "https://mowebsite.ch/"
            },
            {
                title: "DEG",
                description: "My Bachelor Thesis",
                url: "https://dialoguing-with-ecologies.org/"
            },
                
            ]
       
        },
        projects: [{
                title: "Energetic",
                year: "2021",
                subtitle: "Interactive Light Installation",
                shortDescription: "Energetic is an interactive light installation realised for the 'Zauberwald Lenzerheide'-festival situated in the Swiss alps. Through buttons visitors were invited to send energetic lines to a big heart-sculpture and light it up accordingly. This allowed a playful interaction with the installation which was also supported with sound. All the parts, including sculpture, buttons and constructions were made by the team.",
                mainImage: require("@/assets/projects/energetic/energetic.jpg"),
                longDescription: "",
                partner: ["EWZ", "Zauberwald Lenzerheide", "NOA", "Kreis16"],
                images: [require("@/assets/projects/energetic/energetic.jpg"), 
                        require("@/assets/projects/energetic/energetic1.png"),
                        require("@/assets/projects/energetic/energetic2.png"),
                        require("@/assets/projects/energetic/energetic3.png"),
                        require("@/assets/projects/energetic/energetic4.jpg"),
                        require("@/assets/projects/energetic/energetic5.jpg"),],
                technicalDetails: ["Tochdesinger running on Mac Pro (Visuals, Maping, Button Logic, Audio", "2 Projectors", "Turning Motor", "Arduino"]
            },
            {
                title: "Zwischenzeiten",
                year: "2019",
                subtitle: "Interactive Kinetic Installation",
                shortDescription: "'Zwischenzeiten' was realized in November 2019 in cooperation with Katholisch Zürich and the church 'Maria Krönung' in Witikon, Zurich. The interactive installation is intended to convey the feeling of spirituality in such a place. As soon as a visitor approaches one of the balloons, it rises into the air. <br/> <br/> A total of 20 balloons placed between the benches of the church formed the room installation. Custom made PCB's and Enclosures were bulit to ensure the interplay between Sensors, Controller and Motor works. The Installation was exhibited 10 Days - also during ceremonies.",
                mainImage: require("@/assets/projects/zwischenzeiten/zwischenzeiten.jpg"),
                longDescription: "",
                partner: ["Katholisch Zürich", "Zürcher Hochschule der Künste", "Roman Engler", "Sophie Anderhub", "Danuka Tomas"],
                images: [
                    require("@/assets/projects/zwischenzeiten/zwischenzeiten.jpg"),
                    require("@/assets/projects/zwischenzeiten/zwischenzeiten1.jpg"),
                    require("@/assets/projects/zwischenzeiten/zwischenzeiten2.jpg"),
                    require("@/assets/projects/zwischenzeiten/zwischenzeiten3.jpg"),
                    require("@/assets/projects/zwischenzeiten/zwischenzeiten5.jpg"),
                    require("@/assets/projects/zwischenzeiten/zwischenzeiten6.jpg"),
                    require("@/assets/projects/zwischenzeiten/zwischenzeiten7.jpg"),
                    require("@/assets/projects/zwischenzeiten/zwischenzeiten8.jpg"),
                    require("@/assets/projects/zwischenzeiten/zwischenzeiten9.jpg"),
                    require("@/assets/projects/zwischenzeiten/zwischenzeiten10.jpg"),
                    require("@/assets/projects/zwischenzeiten/zwischenzeiten11.jpg"),
                ]
            },
            {
                title: "Spine",
                year: "2019",
                subtitle: "Light Installation",
                shortDescription: "Spine is a light that visualizes the transportation of #information through the human body. „Everyday our innner light reflects in its surroundings and shines outwards.” <br>  <br> The goal of this installation was to fill the room with indirect light which is reflected on the coloured objects made out of paper. The selection of different FL-Tubes varying in their color spectrum let these objects appear differently. A programmed sequence brings an interesting movement to the whole piece.",
                mainImage: require("@/assets/projects/spine/spine.jpg"),
                longDescription: "",
                partner: ["Zürcher Hochschule der Künste", "Fabian Frey", "Roman Engler"],
                images: [
                    require("@/assets/projects/spine/spine.jpg"),
                    require("@/assets/projects/spine/spine1.jpg"), 
                    require("@/assets/projects/spine/spine2.jpg"), 
                    require("@/assets/projects/spine/spine3.jpg")],
                technicalDetails: ["ESP32", "3D-Printed Mechanics", "Balloons", "PIR-Sensors", "Motors"]
            },
            {
                title: "Grillentanz",
                year: "2019",
                subtitle: "Live Visuals",
                shortDescription: "The Grillentanz Festvial is a one-day music event in Arbon, Switzerland with various national and international artist. The mainstage was equipped with a LED-Panel distributed over the stage, was set up by Frick Sound n' Light. The prepared video content for the bands playing on the stage was mapped and triggered through Resolume Arena.",
                mainImage: require("@/assets/projects/grillentanz/grillentanz.jpg"),
                longDescription: "",
                partner: ["Grillentanz Festival", "FSL Schweiz"],
                images: [
                    require("@/assets/projects/grillentanz/grillentanz.jpg"),
                    require("@/assets/projects/grillentanz/grillentanz1.jpg"),
                    require("@/assets/projects/grillentanz/grillentanz2.jpg"),
                    require("@/assets/projects/grillentanz/grillentanz3.jpg"),
                    require("@/assets/projects/grillentanz/grillentanz5.jpg"),
                    require("@/assets/projects/grillentanz/grillentanz6.jpg"),
                    require("@/assets/projects/grillentanz/grillentanz7.jpg"),
                ],
                technicalDetails: ["Resolume Arena (Trigger Clips and Mapping)", "After Effects (Content Preparation)", "Midi-Controller", "LED-Panels"]
            },
        ],
        experiments: [{
                title: "Blimpy",
                year: "2020",
                subtitle: "Kinetic Visuals",
                shortDescription: "Blimpy is a framework of helium drones combined with a dynamic projection mapping environment created by the Zurich Univerity of The Arts. Togehter with my teampartner Andy Kirk, we were able to modify the given framework and shape it to an own artpiece. <br> <br> The outcome of this 2-week-project was a flying, semi-transparent projection canvas that could be positioned in the space with motors. In combination with blender, a three dimensional visual show was created supported by sound.",
                mainImage: require("@/assets/projects/blimpy/blimpy.png"),
                longDescription: "",
                partner: ["Zürcher Hochschule der Künste", "Andy Kirk"],
                images: [
                    require("@/assets/projects/blimpy/blimpy.png"),
                    require("@/assets/projects/blimpy/blimpy1.jpg"),
                    require("@/assets/projects/blimpy/blimpy2.jpg"),
                    require("@/assets/projects/blimpy/blimpy3.jpg"),
                    require("@/assets/projects/blimpy/blimpy5.jpg"),
                    require("@/assets/projects/blimpy/blimpy6.jpg"),
                    require("@/assets/projects/blimpy/blimpy7.jpg"),
                    require("@/assets/projects/blimpy/blimpy8.jpg"),
                    require("@/assets/projects/blimpy/blimpy9.jpg"),
                    require("@/assets/projects/blimpy/blimpy10.jpg"),
                ]
            },
            {
                title: "Adaptive Fluids",
                year: "2019",
                subtitle: "Form Study",
                shortDescription: "'Adaptive Fluids' is a digitally fabricated, physical Animation based on the principle of a so-called Zoetrope. The movement of the sequenced objects is based on the behavior of entangled fludis. <br><br> After researching key-parameters of such phenomena a threedimensional mesh was generated within grashopper (A rhino extension for parametric design). The cutout of this form was then sliced and lasercutted in plexiglass. Roughly 400 flat pieces resulted of this process that were then assembled togehter.",
                mainImage: require("@/assets/projects/zoetrope/zoetrope.png"),
                longDescription: "",
                partner: ["Zürcher Hochschule der Künste'", "Roman Engler", "Andy Kirk", "Fabian Frey"],
                images: [
                    require("@/assets/projects/zoetrope/zoetrope.png"),
                    require("@/assets/projects/zoetrope/zoetrope1.jpg"),
                    require("@/assets/projects/zoetrope/zoetrope2.jpg"),
                    require("@/assets/projects/zoetrope/zoetrope3.jpg"),
                    require("@/assets/projects/zoetrope/zoetrope4.gif"),
                    require("@/assets/projects/zoetrope/zoetrope5.gif"),
                    require("@/assets/projects/zoetrope/zoetrope6.png"),
                    require("@/assets/projects/zoetrope/zoetrope7.png"),
                    require("@/assets/projects/zoetrope/zoetrope8.png"),
                   
                ]
            },
            {
                title: "Lichtgestalten",
                year: "2020",
                subtitle: "Light Object",
                shortDescription: "Das Konzept der App “ES50 360°” beschäftigt sich mit dem Bedürfnis, die Energiestrategie 2050 der Schweiz welche im Jahre 2020 beschlossen wurde, für die schweizer Bevölkerung zugänglicher zu machen. Durch diverse Massnahmen in verschiedenen Sektoren, welche einerseits für die Zunkunft beschlossen wurden, aber teilweise auch schon in Kraft sind, wirken diese Aktionen für aussenstehende oft nicht verständlich. Durch die Verräumlchung dieser Meilensteine mithilfe von Augmented-Reality wird den User*innen ermöglicht, diese in der persönlichen Umgebung zu erforschen. Durch Gestik oder aktives Bewegen im Raum kann man sich somit durch die Zeitachse bewegen, welche mit Objekten bestückt sind die einen Informationspunkt markieren. Diese werden durch verschiedene Medieninhalte wie Audio, Text oder Bild erläutert.",
                mainImage: require("@/assets/projects/lichtgestalten/lichtgestalten.png"),
                longDescription: "",
                partner: ["mo'", "Roman Engler", "Zoe Urand", "Shafira Nugroho"],
                images: [
                    require("@/assets/projects/lichtgestalten/lichtgestalten.png"),
                    require("@/assets/projects/lichtgestalten/lichtgestalten1.png"),
                    require("@/assets/projects/lichtgestalten/lichtgestalten2.png"),
                    require("@/assets/projects/lichtgestalten/lichtgestalten3.png"),   
                ]
            },
            {
                title: "Katharsis",
                year: "2019",
                subtitle: "Conceptual Space",
                shortDescription: "Under the motto of 'hacking values' we created an ambient space in an abandoned tunnel right under the railway tracks of Zurich main station that allows people to espace everyday’s stress by having a mindful experience. <br> <br> The space intentionally plays with the perception of darkness and its adaptation to the human eye over time. The idea was to make visitors overcome fear of darkness through their Katharsis (other word for a hero's journey) to find calmness in a busy place",
                mainImage: require("@/assets/projects/katharsis/katharsisPlaceholder.png"),
                longDescription: "",
                partner: ["Zürcher Hochschule der Künste'", "Roman Engler", "Lars Kienle", "Juliane Weigel", "Kendra Alder"],
                images: [
                    require("@/assets/projects/katharsis/katharsisPlaceholder.png")]
            },
        ],
        portfolio: [{
            title: "ES50 360°",
            year: "2021",
            subtitle: "App zur Energiestrategie 2050",
            mainImage: require("@/assets/projects/es50360/es50_4.png"),
            shortDescription: "Das Konzept der App “ES50 360°” beschäftigt sich mit dem Bedürfnis, die Energiestrategie 2050 der Schweiz welche im Jahre 2020 beschlossen wurde, für die schweizer Bevölkerung zugänglicher zu machen. Durch diverse Massnahmen in verschiedenen Sektoren, welche einerseits für die Zunkunft beschlossen wurden, aber teilweise auch schon in Kraft sind, wirken diese Aktionen für aussenstehende oft nicht verständlich. Durch die Verräumlchung dieser Meilensteine mithilfe von Augmented-Reality wird den User*innen ermöglicht, diese in der persönlichen Umgebung zu erforschen. Durch Gestik oder aktives Bewegen im Raum kann man sich somit durch die Zeitachse bewegen, welche mit Objekten bestückt sind die einen Informationspunkt markieren. Diese werden durch verschiedene Medieninhalte wie Audio, Text oder Bild erläutert.",
            partner: ["Danuka Tomas", "Zoe Urand", "Zürcher Hochschule der Künste"],
            images: [
                require("@/assets/projects/es50360/es50.png"),
                require("@/assets/projects/es50360/es50_1.png"),
                require("@/assets/projects/es50360/es50_2.png"),
                require("@/assets/projects/es50360/es50_3.png"),
                require("@/assets/projects/es50360/es50_4.png"),
                require("@/assets/projects/es50360/es50_5.png"),   
            ]
        },
        {
            title: "Zeigling",
            year: "2018",
            subtitle: "Ein Toolset fürs Selbstwertgefühl",
            shortDescription: "#ZEIGLING ist ein Projekt, das die Menschen dazu ermutigen soll, Spuren in ihrer Umgebung zu hinterlassen, und zwar in Form von kleinen Keramikfiguren. Unsere Inspiration stammt aus der die Zusammenarbeit mit den Patientinnen und Patienten des Ateliers-Living Museum in Wil SG, insbesondere die kreative, positive und sichere Atmosphäre des Keramik-Ateliers. Wir haben mit den Patienten eine Toolset entwickelt, die sie dazu inspiriert, dieses Gefühl des Ortes auf andere Umgebungen zu übertragen. Indem wir kreative, bewusste Spuren hinterlassen, können wir einen Anspruch auf unsere Umwelt erheben. Das Aufsuchen von Orten, die uns wichtig sind, von Orten, an denen wir eine Spur hinterlassen möchten, ermöglicht es uns, eine engere Beziehung zu den Wegen einzugehen, die wir jeden Tag gehen. Für die Menschen die von der Gesellschaft eher ignoriert werden, die sich sonst in ihr Schneckenhaus zurückziehen würden, kann das Hinterlassen auch kleinster Spuren ein kraftvoller Akt sein.",
            mainImage: require("@/assets/projects/zeigling/zeigling.jpg"),
            longDescription: "",
            partner: ["Roman Engler", "Zoe Urand", "Andy Kirk", "ZHdK "],
            images: [
                require("@/assets/projects/zeigling/zeigling.jpg"),
                require("@/assets/projects/zeigling/zeigling1.jpg"),
                require("@/assets/projects/zeigling/zeigling2.jpg"),
                require("@/assets/projects/zeigling/zeigling3.jpg"),
                require("@/assets/projects/zeigling/zeigling4.jpg"),
                require("@/assets/projects/zeigling/zeigling5.jpg"),
                require("@/assets/projects/zeigling/zeigling6.jpg"),
                require("@/assets/projects/zeigling/zeigling7.jpg"),
                require("@/assets/projects/zeigling/zeigling8.jpg"),
                require("@/assets/projects/zeigling/zeigling9.jpg"),
                require("@/assets/projects/zeigling/zeigling10.jpg"),
                require("@/assets/projects/zeigling/zeigling11.jpg"),  
                require("@/assets/projects/zeigling/zeigling12.jpg"),   
            ]
        },
        {
            title: "Sennheiser Remark",
            year: "2020",
            subtitle: "Ein Highlighter für Audio",
            shortDescription: "Stellen Sie sich vor, Sie könnten Live-Audiostreams anzapfen, vielleicht sind Sie gerade in einer Vorlesung oder einem Meeting und können ohne zusätzlichen Aufwand markieren, was Sie wichtig finden.  Oder vielleicht haben Sie nicht verstanden, was gesagt wurde. Stellen Sie sich vor, Sie könnten später einfach zu den letzten paar Sätzen zurückkehren. <br> ReMark ist im Grunde ein Textmarker für Audio. Zum einen ist es eine Anwendung, die es dem Benutzer ermöglicht, Teile eines kontinuierlichen Audiostroms zu markieren und zu speichern. Aber es ist auch viel mehr. Kombiniert mit einer Suchfunktion, automatisch generierten Bewertungen und integriert in Sennheisers' MobileConnect, ermöglicht es den Nutzern, sich wieder mit der Quelle der Information zu verbinden. Die Authentizität von Live-Situationen wird mit der Flexibilität von On-Demand-Inhalten kombiniert. ",
            mainImage: require("@/assets/projects/remark/remark.png"),
            longDescription: "",
            partner: ["Sennheiser", "Andy Kirk", "Ismael Möri", "ZHdK"],
            images: [
                require("@/assets/projects/remark/remark.png"),
                require("@/assets/projects/remark/remark1.png"),
                require("@/assets/projects/remark/remark2.png"),
                require("@/assets/projects/remark/remark3.png"), 
                require("@/assets/projects/remark/remark4.png"),
                require("@/assets/projects/remark/remark5.png"),
                require("@/assets/projects/remark/remark6.png"),   
                require("@/assets/projects/remark/remark6.png"),   
            ]
        },
        {
            title: "OneTwoTree",
            year: "2019",
            subtitle: "Datenvisualisierung",
            shortDescription: "Das Projekt One Two Tree ist eine Visualisierung eines Geo-Datensatzes des Wiederaufforstungspotenzials von Flächen der Erde, erfasst vom Crowther Lab der ETH Zürich. Ziel des Projekts war es, eine ineraktive Karte zu entwickeln, durch welche Besucher*innen an diversen Ausstellungen und Messen die Dringlichkeit der Aufforstung erkennen und auch direkt lokale projekte unterstützen können. Das ganze Konzept wurde für einen 1m x 2m grossen Touchscreen Tisch entwickelt. Als physiche Orientierung wurden zusätzlich Tokens entwickelt, mit welchem User*innen auf der Karte navigieren konnten. So können Orte auf der Karte entdeckt und nach ihrem Aufforstungspotential abfragen. Zusätzlich lässt sich pro Ort je ein Szenario abfragen, welches die Situation an dieser Lokalität darstellt, wenn man nicht handelt, sowie wenn  Teile dieses Potentials ausgeschöpft werden können.",
            mainImage: require("@/assets/projects/onetwotree/onetwotree3.jpg"),
            longDescription: "",
            partner: ["ETH", "Pascal Jeker", "Pamela Schmiedinger", "Sophie Anderhub", "ZHdK"],
            images: [
                require("@/assets/projects/onetwotree/onetwotree.jpg"),
                require("@/assets/projects/onetwotree/onetwotree1.jpg"),
                require("@/assets/projects/onetwotree/onetwotree2.jpg"),
                require("@/assets/projects/onetwotree/onetwotree3.jpg"),
                require("@/assets/projects/onetwotree/onetwotree4.jpg"),
                require("@/assets/projects/onetwotree/onetwotree5.jpg"),
                require("@/assets/projects/onetwotree/onetwotree6.jpg"),
                require("@/assets/projects/onetwotree/onetwotree7.jpg"),        
            ]
        },
        {
            title: "Leihs System",
            year: "2019",
            subtitle: "Redesign eines Ausleihesystems",
            shortDescription: "Leihs ist ein Ausleihesystem von Technikmaterial für Studierende und Dozierende, welches von der Zürcher Hochschule der Künste entwickelt wurde. Inzwischen wurde dieses System von zahlrechen Schulen in Europa übernommen und auf ihre Bedürfnisse angepasst. Da das alte User Interface haupsächlich für Desktopansichten gestaltet wurde, wurde von der tecnischen Leitung ein Redesign-Prozess lanciert, welcher dazu dienen sollte, die Oberflächen intuitiver zu gestalten, um somit einen angenehmeren Bestellprozess für Studierende zu ermöglichen. In einem integrativen Prozess wurden durch User-Befragungen und Prototypen Problemstellen in der Benützung elaboriert, sowie Wünsche für neue Funktionen erfragt. Die Schwierigkeit lag hierbei die grosse Bandbreite an Nutzer*innen abzudeken, welcher von Einsteiger*innen bis zu Pro-User*innen reicht. <br> So wurde bespielsweise die Verfügbarkeitsabfrage an Daten optimiert. Ebenfalls wurde kategoriesierte Suche durch eine Tag-Suche ersetzt. Eine favorisierung von Items ermöglicht wiederkehrenden User*innen ein schelles erneutes Buchen von Geräten.",
            mainImage: require("@/assets/projects/leihs/leihs1.png"),
            longDescription: "",
            partner: ["Adri Bodor-Pek", "Tim Fuchs", "Sonjoi Nielsen", "ZHdK"],
            images: [
                require("@/assets/projects/leihs/leihs.png"),
                require("@/assets/projects/leihs/leihs1.png"),
                require("@/assets/projects/leihs/leihs2.png")
            ]
            },           

        {
            title: "Exostructure",
            year: "2021",
            subtitle: "Stadtplaungskonzept",
            shortDescription: "Das Konzept Exostructure beschäftigt sich mit der Nutzung von öffentlichem Raum in Städten. Das spekulative Projekt bedient sich an neuen technologischen Möglchkeiten, städtische Infrastrukturen dynamisch nutzbar zu machen. Grundbaustein hierfür ist eine dynmaschie Strassenmarkierung welche nach Bedürfnissen der Stadtbevölkerung angepasst werden kann. Dies ermöglicht eine Umwandlung von wertvoller Fläche wie zum Beispiel einer ungneutzten Seitenstrasse in eine Spielfläche für Kinder oder einen Parkplatz in eine Aussenfläche für ein Kaffee.",
            mainImage: require("@/assets/projects/exostructure/exostructure.png"),
            longDescription: "",
            partner: ["Stefan Hürlemann", "ZHdK"],
            images: [
                require("@/assets/projects/exostructure/exostructure.png"),
                require("@/assets/projects/exostructure/exostructure1.png"),
                require("@/assets/projects/exostructure/exostructure2.png"),
                require("@/assets/projects/exostructure/exostructure3.png"),   
            ]
        },
        ],

        contact: [{
                text: "write me an email",
                url: "mailto:info@andreasbuetler.ch"
            },
            {
                text: "visit my instagram page",
                url: "https://www.instagram.com/andreasbuetler/"
            },
            {
                text: "connect on linkedin",
                url: "https://www.linkedin.com/in/andreas-b%C3%BCtler-1a2647173/"
            },
            {
                text: "visit me",
                url: "https://baustell.ch/"
            },
            {
                text: "call me on telegram",
                url: "https://t.me/andreasbuetler"
            },
            {
                text: "follow me on github",
                url: "https://github.com/andreasbuetler"
            },

        ],
    }


}
