<template>
  <div id="app" body-scroll-lock>

    <div class="background animated"></div>
    <!-- <div class="background"></div> -->
    <personalHeader />
    <navigation id="nav" />
    <!-- <router-view v-slot="{ activeComponent }">
      <Transition name="fade" mode="out-in">
  <component :is="activeComponent"></component>
  </Transition>
    </router-view> -->
  
    <router-view>
    </router-view>

    <!-- <mainPageContent/> -->
  </div>
</template>


<script>
import theHeader from "@/components/theHeader.vue";
import navigation from "@/components/navigation.vue";

// import mainPageContent from "@/views/mainPageContent.vue"


export default {
  name: "App",
  props: {
    msg: String,
  },
  components: {
    personalHeader: theHeader,
    navigation: navigation,
    //  mainPageContent: mainPageContent
  },
  data() {
    return {
      isLoading: true,
    };
  },
  mounted() {
    if (!localStorage.getItem("wasHere")) {
      setTimeout(() => {
        this.isLoading = false;
        localStorage.setItem("wasHere", true);
      }, 5000);
    } else {
      this.isLoading = false;
    }
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    window.addEventListener("resize", () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  },
};
</script>

<style lang="scss">
body{
  cursor: url("assets/icons/cursorDot.svg"), default;
}

$font-dir: "/fonts/";

@font-face {
  font-family: "Font";
  font-weight: regular;
  src: url("#{$font-dir}/SuisseIntl-Regular.ttf");
}


#app {
  font-family: Font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: $fontColor;
  display: grid;
  grid-template-columns: 3rem auto;
  grid-template-rows: $headerSize auto;
  grid-template-areas:
    "header header"
    "navbar content";
  justify-items: stretch;
  align-items: stretch;
  align-content: stretch;
  justify-content: stretch;
  height: calc(var(--vh, 1vh) * 100);
  width: calc(var(--vw, 1vw) * 100);
  // border-top: $border;
  // border-left: $border;
  // border-bottom: $border;
  box-sizing: border-box;
  // line-height: 1rem;
  // overflow: hidden;
  @media screen and (min-device-width: 1000px){
    grid-template-columns: 120px auto;
    grid-template-rows: 120px auto;
  }
  // align-content: end;
}

#nav {
  grid-area: navbar;

  a {
    color: $fontColor;

    &.router-link-exact-active {
      color: $backgroundColor;
      background-color: $primaryColor;
    }
  }
}
#router-view {
  grid-area: content;
  border: $border;
}
* {
  margin: 0px;
  // padding: 0px;
}
a {
  color: inherit;
  text-decoration: none;
  cursor: url("assets/icons/cursorCircle.svg"), pointer;
}
.loading {
  grid-template-rows: 100vh 100vh;
}

// ::-webkit-scrollbar {
//     // display: none;

// }
::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  -webkit-appearance: none;
}
::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0);
}
::-webkit-scrollbar-thumb {
  background: $primaryColor;
}

h1 {
  font-size: 2rem;
  font-variation-settings: "wght" 400;
  padding-bottom: 0.5rem;
}

h2 {
  font-size: 1.3rem;
  font-variation-settings: "wght" 100;
  @media screen and (min-device-width: 1000px){
    font-size: 1.5rem;
  }
}

h3 {
  font-variation-settings: "wght" 600;
  font-size: 1.5rem;
  padding-bottom: 0.3rem;
}
h4 {
  font-variation-settings: "wght" 400;
  font-size: 1rem;
  // padding-bottom: $padding;
}
p {
  //margin-top: 10px;
  white-space: pre-line;
  padding-bottom: $padding;
  @media screen and (min-device-width: 1000px){
    display: block;
  }
}
.background {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: -1;
  background: radial-gradient(circle farthest-side, rgb(255, 255, 255) 0%,rgb(253, 255, 227) 36%,rgb(255, 229, 216) 71%,rgb(222, 238, 255) 100%);
  background-size: 200%;
  
}

.animated{
  animation-name: movement;
    animation-duration: 15s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: normal;

} 

@keyframes movement{
0%{
  background-position-y: 0%;
  background-position-x: 0%;
}
25%{
  background-position-y: 100%;
  background-position-x: 0%;
}
50%{
  background-position-y: 100%;
  background-position-x: 100%;
}
75%{
  background-position-y: 0%;
  background-position-x: 100%;
}

100%{
  background-position-y: 0%;
  background-position-x: 0%;
}


}

.glide__bullet {

  cursor: pointer;
  border-left: $border;
  border-top: $border;
  border-bottom: none;
  border-right: none;
  background: none;
}
.glide__bullets{
  display: grid;
  grid-auto-flow: column;
  // height: 16px;
}

.glide__bullet--active {
  background-color: $primaryColor;
  background-size: 400% 400%;
}
.glide__slides{
  height: 100%;
}

.glide{
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: 1fr 8px;
  
  border: 0px;
  box-sizing: content-box;
  
}
.UxButton{
  background-color: $primaryColor;
  color: $backgroundColor;
  grid-area: header;
  width: 3rem;
  height: 3rem;
  padding: 1rem;
  z-index: 10;
  border-radius: 20rem;
  margin: 1rem;
}
</style>
