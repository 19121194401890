import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/mainPageContent.vue'),
    meta: { backButton: false }

  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/mainPageContent.vue'),
    meta: { backButton: false }

  },
  {
    path: '/projects',
    name: 'projects',
    component: () => import('@/views/mainPageContent.vue'),
    meta: { backButton: false }

  },
  {
    path: '/experiments',
    name: 'experiments',
    component: () => import('@/views/mainPageContent.vue'),
    meta: { backButton: false }
    
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/mainPageContent.vue'),
    meta: { backButton: false, portfolio: false }

  },
  {
    path: '/project/:projectName',
    name: 'project',
    component: () => import('@/views/projectDetailView.vue'),
    meta: { backButton: true, portfolio: false }
  },
  {
    path: '/experiment/:projectName',
    name: 'experiment',
    component: () => import('@/views/experimentDetailView.vue'),
    meta: { backButton: true ,portfolio: false}
  },
  {
    path: '/portfolio/:projectName',
    name: 'portfolio',
    component: () => import('@/views/portfolio.vue'),
    meta: { backButton: true, portfolio: true }
  },
  {
    path: '/portfolio',
    name: 'portfolioMain',
    component: () => import('@/views/portfolioPageContent.vue'),
    meta: { backButton: false, portfolio: true }
  },
  {
    path: '/portfolio/about',
    name: 'portfolioAbout',
    component: () => import('@/views/portfolioPageContent.vue'),
    meta: { backButton: false, portfolio: true }
  },
  {
    path: '/portfolio/contact',
    name: 'portfolioContact',
    component: () => import('@/views/portfolioPageContent.vue'),
    meta: { backButton: false, portfolio: true }
  },
  {
    path: '/cv',
    name: 'cv',
    component: () => import('@/views/cv.vue'),
    meta: { backButton: true, portfolio: false }
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      const position = {};
      if (to.hash) {
        position.selector = to.hash;
        return false;
      }
    }
  }

})


export default router
